import React from 'react';

import SEO from '../components/seo';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { motion } from 'framer-motion';
import { Projects } from '../components/projects-list';

const useStyles = makeStyles((theme) => {
  return {
    section: {
      margin: theme.spacing(15, 0, 8, 0),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(5, 0, 10, 0),
      },
    },
    intro: {
      fontSize: '1.3rem',
    },
  };
});

const ProjectsPage = () => {
  const { section, intro } = useStyles();
  return (
    <>
      <SEO title='Projects' />
      <motion.div key='projects' initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 1 } }}>
        <Box component='section' className={section}>
          <Box>
            <Typography variant='h3' paragraph>
              Code Projects
            </Typography>
            <Typography variant='body1' className={intro} paragraph>
              I love to tinker with code, libraries, public APIs, and even just commonly visited websites. I almost
              always have an idea for a new side project, although they don't all come to fruition. This page displays
              some of the projects I have completed in my personal time as a means of practicing and improving my
              development skills. In addition, my Github profile hosts numerous other projects, all completed in my
              limited personal time and while holding full time professional development jobs.
            </Typography>
            <Typography variant='body1' className={intro}>
              Never stop learning!
            </Typography>
          </Box>
        </Box>
        <Projects />
      </motion.div>
    </>
  );
};

export default ProjectsPage;
